import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

import { tokens } from '../../tokens';

import { useListItemGroup } from './context';
import { ControlVariantOptions, ListItemControl } from './list-item-control';

export type ListItemProps = ViewProps;

export const ListItem: React.FC<ListItemProps> = ({ ...viewProps }: ListItemProps) => {
  const { items, error: listGroupError } = useListItemGroup();

  return (
    <View style={[styles.container, listGroupError && styles.errorState]} {...viewProps}>
      {items.map((item: ControlVariantOptions, index: number) => (
        <ListItemControl
          item={item}
          style={[
            styles.controlItem,
            index !== items.length - 1 && styles.controlItemDivider,
            listGroupError && styles.errorState,
          ]}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderColor: tokens.colors.$blackOpacity10,
    borderRadius: 8,
    borderWidth: 1,
  },
  controlItem: {
    justifyContent: 'space-between',
    padding: 12,
  },
  controlItemDivider: {
    borderColor: tokens.colors.$blackOpacity04,
    borderBottomWidth: 1,
  },
  errorState: {
    borderColor: tokens.colors.$error,
  },
});
