import { TouchableWithoutFeedback, type TouchableWithoutFeedbackProps } from 'react-native';

export type PickerTouchableWithoutFeedbackProps = TouchableWithoutFeedbackProps;

export const PickerTouchableWithoutFeedback = (
  touchableProps: PickerTouchableWithoutFeedbackProps
) => {
  return (
    <TouchableWithoutFeedback
      // Accessible set to false because the control should be the accessible element, this would be redundant
      accessible={false}
      aria-hidden
      // @ts-ignore RN does not like that we are making the touchable non-focusable
      focusable={false}
      {...touchableProps}
    />
  );
};
