import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, ScrollView, Text, YStack, createMqStyles, tokens, useMqSelect } from '@fhs/ui';
import { List } from '@fhs/ui/src/components/list';
import { PickerSelected } from '@fhs/ui/src/components/picker/picker-selected';
import { ToastVariant, useToast } from '@fhs-legacy/universal-components';

import type { MenuItem } from '../types';

import { ComboUpSell } from './combo-up-sell';
import { IngredientReplacement } from './ingredient-replacement';
import { sizeLabelMapping } from './ingredient-replacement/constants';
import { ProductDetailLayout } from './product-detail-layout';
import { ProductNameAndImage } from './product-name-and-image';
import { SizeSelector } from './size-selector';

type MenuSectionProps = {
  item: MenuItem;
};

export function MenuItemDetails(props: MenuSectionProps) {
  const { show } = useToast({
    placement: 'bottom',
  });
  const { item } = props;
  const [sizeValue, setSizeValue] = useState<keyof typeof sizeLabelMapping>('medium-sandwich');
  const [comboUpSell, setComboUpSell] = useState<boolean>(false);
  const [ingredientReplacement, setIngredientReplacement] = useState<boolean>(false);

  // Make it a combo
  const [makeItCombo, setMakeItCombo] = useState<boolean>(false);
  const [drinkPicker, setDrinkPicker] = useState<PickerSelected>(null);
  const [sidePicker, setSidePicker] = useState<PickerSelected>(null);

  const handleSizeChange = (value: keyof typeof sizeLabelMapping) => {
    setSizeValue(value);
  };

  const triggerToast = (id: string, variant: ToastVariant) => {
    show({
      id: id,
      variant: variant,
      text: "I'm a fake toast",
    });
  };

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (hasError) {
      // Simulate an error after component has rendered
      throw new Error('Error triggered from useEffect');
    }
  }, [hasError]);

  const isDesktop = useMqSelect({ $gteDesktop: true }, false);
  const mqStyles = useMqStyles();

  return (
    <ProductDetailLayout
      nameAndImageContent={
        <ProductNameAndImage
          displayName={item.displayName}
          description={item.description}
          variant="Medium Size"
          cals={'420 cal'}
          asHero={isDesktop || !item.variants}
          imageUrl={item.image.asset.uri}
        />
      }
      customizationContent={
        item.variants ? (
          <>
            <YStack style={[styles.containerSection, styles.containerPadding]}>
              <Text.Heading type="four">Size</Text.Heading>
            </YStack>
            <ScrollView
              horizontal
              style={mqStyles.sizePickerContainerFlexGlow}
              contentContainerStyle={styles.sizePickerContentContainer}
              showsHorizontalScrollIndicator={false}
            >
              <SizeSelector value={sizeValue} onChange={handleSizeChange} />
            </ScrollView>
            <YStack style={[styles.containerSection, styles.containerPadding]}>
              <Text.Heading type="four">Choices</Text.Heading>
            </YStack>
            <View style={{ padding: 16 }}>
              <Button
                style={styles.dispatchButton}
                onPress={() => {
                  setComboUpSell(prev => !prev);
                }}
                aria-label="Dispatch ComboUpSell"
                size="sm"
              >
                <Button.Text>Dispatch ComboUpSell</Button.Text>
              </Button>
            </View>
            <View style={{ padding: 16 }}>
              <Button
                style={styles.dispatchButton}
                onPress={() => {
                  setIngredientReplacement(prev => !prev);
                }}
                aria-label="Dispatch IngredientReplacement"
                size="sm"
              >
                <Button.Text>Dispatch IngredientReplacement</Button.Text>
              </Button>
            </View>
            <View style={{ padding: 16 }}>
              <Button
                style={styles.dispatchButton}
                onPress={() => {
                  triggerToast('fake-positive-toast-id', 'positive');
                }}
                aria-label="Dispatch Positive Toast"
                size="sm"
              >
                <Button.Text>Dispatch Positive Toast</Button.Text>
              </Button>
            </View>
            <View style={{ padding: 16 }}>
              <Button
                style={styles.dispatchButton}
                onPress={() => {
                  triggerToast('fake-negative-toast-id', 'negative');
                }}
                aria-label="Dispatch Negative Toast"
                size="sm"
              >
                <Button.Text>Dispatch Negative Toast</Button.Text>
              </Button>
            </View>
            <View style={{ padding: 16 }}>
              <Button
                style={styles.dispatchButton}
                onPress={() => setHasError(true)}
                aria-label="Dispatch Error State"
                size="sm"
              >
                <Button.Text>Dispatch Error State</Button.Text>
              </Button>
            </View>
            <ComboUpSell
              headerTitle="Make it a Combo"
              acceptButtonTitle="Make it a Combo"
              closeButtonTitle="No, thanks"
              description="Add any size drink + chips or cookie for only"
              price="$3.49"
              isVisible={comboUpSell}
              handleAccept={() => setComboUpSell(false)}
              handleClose={() => setComboUpSell(false)}
            />
            <IngredientReplacement
              headerTitle="Ingredient Replacement"
              acceptButtonTitle="Replace"
              closeButtonTitle="Cancel"
              items={[
                {
                  from: {
                    image:
                      'https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/03c4a4563f43f8cee8c7712a7ac97489232fc744-1699x2467.png?sat=-100',
                    name: 'Gluten Free Roll',
                    blurhash: 'dmS5^h%2.TNdxuayWBoL.9R*McxZaej[ofayozj[V@f6',
                  },
                  to: {
                    image:
                      'https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/e16be18aa34944f6642ab890053c9b85725ade84-1640x2556.png',
                    name: 'White Roll',
                    blurhash: 'dwRovu%L.TI:off6bHfk%$RkMwxaoffkjZf6tRayRPoe',
                  },
                },
              ]}
              isVisible={ingredientReplacement}
              sizeLabel={sizeLabelMapping[sizeValue].label}
              handleReplace={() => setIngredientReplacement(false)}
              handleClose={() => setIngredientReplacement(false)}
            />
            <List
              items={[
                {
                  variant: 'picker',
                  options: {
                    handlePress: () => {
                      setSidePicker({
                        image: null,
                        value: 'Drink selected',
                        label: 'Drink picker selected',
                      });
                    },
                    selected: sidePicker,
                    type: 'SIDE',
                  },
                },
                {
                  variant: 'picker',
                  options: {
                    handlePress: () => {
                      setDrinkPicker({
                        image: null,
                        value: 'Side selected',
                        label: 'Side picker selected',
                      });
                    },
                    selected: drinkPicker,
                    type: 'DRINK',
                  },
                },
              ]}
              header={{
                enable: makeItCombo,
                onPress: (newValue: boolean) => {
                  setMakeItCombo(newValue);
                },
                showGlobalListStateSwitch: true, // enable switch on title
                title: 'Make it a combo',
                switchText: '$3.99',
                subTitle: 'You want it! :)',
              }}
              style={styles.list}
            >
              <List.Header />
              <List.Items />
            </List>
          </>
        ) : null
      }
    />
  );
}

const styles = StyleSheet.create({
  container: {
    // TOOD(simplified-menu): Use media query
    backgroundColor: tokens.colors.$white,
    alignSelf: 'center',
    // TODO(simplified-menu): Update to use tokens, or add Media Query
    maxWidth: 620,
  },
  containerPadding: {
    paddingHorizontal: 16,
  },
  containerSection: {
    marginVertical: 16,
  },
  productDetailSectionHeader: {
    padding: 16,
    gap: 16,
  },
  dispatchButton: {
    padding: 16,
    gap: 16,
  },
  list: {
    padding: 16,
    gap: 16,
  },
  sizePickerContentContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

const useMqStyles = createMqStyles({
  sizePickerContainerFlexGlow: {
    $gteDesktop: {
      flexGrow: 0,
      paddingHorizontal: 52,
      marginHorizontal: -52,
    },
  },
});
