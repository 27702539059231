import { createContext, useContext } from 'react';

import { GlobalHeaderProps, GlobalStateProps } from './components/type';
import { ControlVariantOptions } from './list-item-control';

export type ListItemGroupContext = GlobalStateProps & {
  items: ControlVariantOptions[];
  header: GlobalHeaderProps;
};

export const ListItemGroupContext = createContext<ListItemGroupContext | null>(null);

export const useListItemGroup = () => {
  const context = useContext(ListItemGroupContext);

  if (__DEV__) {
    if (context == null) {
      throw new Error('useItemList usage must be wrapped within a ItemList.');
    }
  }

  return context as ListItemGroupContext;
};
