import { Image } from 'expo-image';
import { StyleSheet } from 'react-native';
import { ImageSourcePropType } from 'react-native/Libraries/Image/Image';

import { IconChevronRight } from '../icon';
import { XStack } from '../stack';

import { PickerLabel } from './picker-label';
import {
  PickerTouchableWithoutFeedback,
  PickerTouchableWithoutFeedbackProps,
} from './picker-touchable-without-feedback';

export type PickerSelected = {
  image: ImageSourcePropType;
  label: string;
  value: string;
};

export type PickerSelectedProps = PickerTouchableWithoutFeedbackProps & {
  disabled?: boolean;
  selected: PickerSelected;
};

export const PickerSelected = ({ disabled, selected, ...touchProps }: PickerSelectedProps) => {
  return (
    <PickerTouchableWithoutFeedback disabled={disabled} {...touchProps}>
      <XStack style={style.container}>
        <Image source={selected.image} style={style.image} />
        <PickerLabel label={selected.label} disabled={disabled} />
        <IconChevronRight />
      </XStack>
    </PickerTouchableWithoutFeedback>
  );
};

const style = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    gap: 16,
  },
  image: {
    aspectRatio: 2 / 1,
    width: 56,
  },
});
