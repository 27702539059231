import { withStaticProperties } from '@fhs/utils';

import { List as ListGroup } from './list';
import { ListHeader as Header } from './list-header';
import { ListItem as Items } from './list-item';

export const List = withStaticProperties(ListGroup, {
  Header,
  Items,
});
