import {
  StyleSheet,
  TouchableWithoutFeedback,
  TouchableWithoutFeedbackProps,
  View,
} from 'react-native';

import { tokens } from '../../tokens';
import { XStack } from '../stack';
import { Switch } from '../switch';
import { Text, TextProps } from '../text';

import { useListItemGroup } from './context';

export type ListItemLabelProps = {
  touchableWithoutFeedbackProps?: TouchableWithoutFeedbackProps;
  textProps?: TextProps;
};

// This could be useful
export const ListHeader = (props: ListItemLabelProps) => {
  const {
    disabled: listDisabled,
    header: { enable, onPress, showGlobalListStateSwitch, subTitle, switchText, title },
  } = useListItemGroup();

  const onClickEvent = () => {
    const isClickEventEnable = listDisabled || !showGlobalListStateSwitch;
    if (isClickEventEnable) {
      return;
    }
    onPress(!enable);
  };

  return (
    <XStack style={styles.container}>
      <TouchableWithoutFeedback
        // Accessible set to false because the control should be the accessible element, this would be redundant
        accessible={false}
        aria-hidden
        // @ts-ignore RN does not like that we are making the touchable non-focusable
        focusable={false}
        onPress={() => onClickEvent()}
        style={styles.labelTouchable}
        {...props.touchableWithoutFeedbackProps}
      >
        <View style={styles.labelContainer}>
          <Text.Heading
            type={'four'}
            {...props.textProps}
            style={[props.textProps?.style, listDisabled && styles.disabledText]}
          >
            {title}
          </Text.Heading>
          {subTitle && (
            <Text.Ui
              {...props.textProps}
              style={[styles.subTitle, listDisabled && styles.disabledText]}
              size={'md'}
            >
              {subTitle}
            </Text.Ui>
          )}
        </View>
      </TouchableWithoutFeedback>

      {switchText && (
        <TouchableWithoutFeedback
          // Accessible set to false because the control should be the accessible element, this would be redundant
          accessible={false}
          aria-hidden
          // @ts-ignore RN does not like that we are making the touchable non-focusable
          focusable={false}
          onPress={() => onClickEvent()}
          style={styles.labelTouchable}
          {...props.touchableWithoutFeedbackProps}
        >
          <Text.Paragraph
            {...props.textProps}
            style={[
              props.textProps?.style,
              styles.price,
              (!enable || listDisabled) && styles.disabledText,
            ]}
            size={'sm'}
          >
            {switchText}
          </Text.Paragraph>
        </TouchableWithoutFeedback>
      )}
      {showGlobalListStateSwitch && (
        <Switch disabled={listDisabled} value={enable} onChange={() => onClickEvent()}>
          <Switch.Control />
        </Switch>
      )}
    </XStack>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingBottom: 12,
  },
  labelContainer: {
    flexDirection: 'column',
    columnGap: 4,
    flex: 1,
  },
  labelTouchable: {
    flex: 1,
  },
  price: {
    paddingRight: 8,
  },
  subTitle: {
    color: tokens.colors.$houseDark,
  },
  disabledText: {
    color: tokens.colors.$disabledText,
  },
});
