import { Slot, Stack } from 'expo-router';
import { Platform } from 'react-native';

import { useMqSelect } from '@fhs/ui';

import { getScreenTitleHeader } from '../../../../components/get-screen-title-header';

export default Platform.select({
  native: Slot,
  web: function MenuLayout() {
    const isDesktop = useMqSelect({ $gteDesktop: true }, false);
    return (
      <Stack screenOptions={{ headerShown: !isDesktop, header: getScreenTitleHeader() }}>
        <Stack.Screen name="index" options={{ title: 'Menu' }} />
      </Stack>
    );
  },
});
