import React from 'react';

import { IQuantityStepperProps, Quantity } from '../../quantity';

export type QuantityStepperControllerProps = IQuantityStepperProps<string>;

export const QuantityStepperController = (props: QuantityStepperControllerProps) => {
  return (
    <Quantity {...props}>
      <Quantity.Label />
      <Quantity.Stepper />
    </Quantity>
  );
};
