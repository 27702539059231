import { Image } from 'expo-image';
import { StyleSheet } from 'react-native';
import { ImageSourcePropType } from 'react-native/Libraries/Image/Image';

import { IconChevronRight } from '../icon';
import { XStack } from '../stack';

import { PickerLabel } from './picker-label';
import {
  PickerTouchableWithoutFeedback,
  PickerTouchableWithoutFeedbackProps,
} from './picker-touchable-without-feedback';

export type PickerNotSelectedProps = PickerTouchableWithoutFeedbackProps & {
  disabled?: boolean;
  imageNotSelected: ImageSourcePropType;
  label: string;
};

export const PickerNotSelected = ({
  disabled,
  imageNotSelected,
  label,
  ...touchProps
}: PickerNotSelectedProps) => {
  return (
    <PickerTouchableWithoutFeedback disabled={disabled} {...touchProps}>
      <XStack style={style.container}>
        <Image contentFit="contain" source={imageNotSelected} style={style.image} />
        <PickerLabel label={label} disabled={disabled} />
        <IconChevronRight />
      </XStack>
    </PickerTouchableWithoutFeedback>
  );
};

const style = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    gap: 16,
  },
  image: {
    width: 40,
    height: 40,
  },
});
