import React from 'react';

import { Switch, SwitchProps } from '../../switch';

export type SwitchControllerProps = SwitchProps;

export const SwitchController = (props: SwitchControllerProps) => {
  return (
    <Switch {...props}>
      <Switch.Label />
      <Switch.Control />
    </Switch>
  );
};
