import { StyleSheet, View } from 'react-native';

import { tokens } from '../../tokens';
import { Pressable, PressableProps } from '../pressable';

import { useSwitch } from './context';

export type SwitchControlProps = PressableProps;

export const SwitchControl = (props: SwitchControlProps) => {
  const { handlePress, value, disabled } = useSwitch();

  return (
    <Pressable
      {...props}
      onPress={handlePress}
      disabled={disabled}
      borderRadius={100}
      focusedStyle={styles.trackFocused}
      disabledStyle={styles.trackDisabled}
      style={[styles.track, props.style, value ? styles.trackPressed : styles.trackNotPressed]}
      role="switch"
      aria-checked={value}
    >
      <View style={styles.thumb} />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  track: {
    display: 'flex',
    width: 48,
    height: 24,
    padding: 3,
    borderColor: 'transparent',
    justifyContent: 'center',
  },
  trackNotPressed: {
    backgroundColor: tokens.colors.$blackOpacity30,
    borderColor: tokens.colors.$blackOpacity10,
    alignItems: 'flex-start',
  },
  trackPressed: {
    backgroundColor: tokens.colors.$houseRedDarken,
    alignItems: 'flex-end',
  },
  trackDisabled: {
    backgroundColor: tokens.colors.$blackOpacity10,
  },
  trackFocused: {
    borderColor: 'transparent',
  },
  thumb: {
    width: 18,
    height: 18,
    backgroundColor: tokens.colors.$white,
    borderRadius: 100,
  },
  focus: {
    borderColor: tokens.colors.$blackOpacity10,
  },
});
