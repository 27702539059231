import { View } from 'react-native';
import { ImageSourcePropType } from 'react-native/Libraries/Image/Image';

import { Picker, PickerProps } from '../../picker';

type PickerTypes = 'DRINK' | 'SIDE';

interface PickerTypeOptions {
  label: string;
  image: ImageSourcePropType;
}

export type PickerControllerProps = Omit<PickerProps, 'label' | 'imageNotSelected'> & {
  type: PickerTypes;
};

const PICKER_OPTIONS: Record<PickerTypes, PickerTypeOptions> = {
  DRINK: { label: 'add drink', image: require('./images/drink.png') },
  SIDE: { label: 'add Side', image: require('./images/side.png') },
};

export const PickerController = ({
  disabled,
  handlePress,
  selected,
  type,
  ...viewProps
}: PickerControllerProps) => {
  const pickerOptions = PICKER_OPTIONS[type];

  const pickerProps: PickerProps = {
    disabled: disabled,
    handlePress: handlePress,
    imageNotSelected: pickerOptions.image,
    label: pickerOptions.label,
    selected: selected,
  };

  return (
    <View {...viewProps}>
      <Picker {...pickerProps} />
    </View>
  );
};
