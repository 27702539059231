import { Platform, StyleSheet, TouchableWithoutFeedback } from 'react-native';

import { tokens } from '../../tokens';
import { Text, TextProps } from '../text';

import { useSwitch } from './context';

export type SwitchLabelProps = {
  textProps?: TextProps;
  touchableProps?: TouchableWithoutFeedback;
};

export function SwitchLabel({ textProps, touchableProps }: SwitchLabelProps) {
  const switchItem = useSwitch();

  if (!switchItem.label) {
    return null;
  }

  return (
    <TouchableWithoutFeedback
      // Accessible set to false because the control should be the accessible element, this would be redundant
      accessible={false}
      aria-hidden
      // @ts-ignore RN does not like that we are making the touchable non-focusable
      focusable={false}
      onPress={switchItem.handlePress}
      {...touchableProps}
    >
      <Text.Ui
        size="md"
        {...textProps}
        style={[
          styles.touchableText,
          textProps?.style,
          switchItem.disabled && styles.touchableTextDisabled,
        ]}
      >
        {switchItem.label}
      </Text.Ui>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  touchableText: {
    alignSelf: 'center',
    ...Platform.select({ web: { cursor: 'pointer' } }),
  },
  touchableTextDisabled: {
    color: tokens.colors.$disabledText,
    ...(Platform.select({ web: { cursor: 'unset' } }) as any),
  },
});
