import { Stack } from 'expo-router';
import { SafeAreaProvider } from 'react-native-safe-area-context';

export default function MenuStackLayout() {
  return (
    <SafeAreaProvider>
      <Stack screenOptions={{ headerShown: false }} />
    </SafeAreaProvider>
  );
}

// Disabled because this is causing module import errors on production build
// Presumably because it duplicate `fresh/routing/src/routes/menu/_layout.tsx`
// export const unstable_settings = {
// };
