import { createContext, useContext } from 'react';

export type SwitchContext = {
  disabled: boolean;
  handlePress: () => void;
  label: string;
  value: boolean;
};

export const SwitchContext = createContext<SwitchContext | null>(null);

export const useSwitch = () => {
  const context = useContext(SwitchContext);

  if (__DEV__) {
    if (context == null) {
      throw new Error('Must have SwitchGroup ancestor.');
    }
  }

  return context as SwitchContext;
};
