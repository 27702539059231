import { PropsWithChildren } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';

import { tokens } from '@fhs/ui';
import { LogoSvg } from '@fhs/ui/src/components/logo';

import { AuthHeader } from '../auth-header';

const styles = StyleSheet.create({
  container: {
    alignContent: 'center',
    alignSelf: 'center',
    backgroundColor: tokens.colors.$white,
    alignItems: 'center',
    aspectRatio: 4 / 3,
    width: 440,
    borderColor: tokens.colors.$black10,
    borderRadius: 12,
    borderWidth: 1,
    padding: 12,
    position: 'absolute',
  },
  content: {
    paddingVertical: 16,
    width: '85%',
  },
  floatingHeader: { position: 'absolute', top: 0 },
  header: { flex: 1, justifyContent: 'center', alignItems: 'center' },

  logo: { alignSelf: 'center', marginVertical: -40 },
});

const { height } = Dimensions.get('window');

export function AuthContainerDesktop({ children }: PropsWithChildren) {
  return (
    <View style={styles.header}>
      <View style={styles.floatingHeader}>
        <AuthHeader height={height / 2} />
      </View>
      <View style={styles.container}>
        <View>
          <LogoSvg style={styles.logo} />
        </View>
        <View style={styles.content}>{children}</View>
      </View>
    </View>
  );
}
