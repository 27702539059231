import { useMemo } from 'react';
import { View, ViewProps } from 'react-native';

import { GlobalHeaderProps, GlobalStateProps } from './components/type';
import { ListItemGroupContext } from './context';

export type ListProps = ViewProps &
  GlobalStateProps &
  ListItemGroupContext & {
    header: GlobalHeaderProps;
  };

export const List: React.FC<ListProps> = ({
  disabled,
  error,
  items,
  header,
  ...viewProps
}: ListProps) => {
  const context = useMemo(
    () => ({ disabled, error, header, items }),
    [disabled, error, header, items]
  );

  return (
    <ListItemGroupContext.Provider value={context}>
      <View {...viewProps} />
    </ListItemGroupContext.Provider>
  );
};
