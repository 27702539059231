import React from 'react';
import { ViewProps } from 'react-native';

import { CheckboxController, CheckboxControllerProps } from './components/checkbox-controller';
import { PickerController, PickerControllerProps } from './components/picker-controller';
import {
  QuantityCounterController,
  QuantityCounterControllerProps,
} from './components/quantity-counter-controller';
import {
  QuantityStepperController,
  QuantityStepperControllerProps,
} from './components/quantity-stepper-controller';
import {
  RadioGroupController,
  RadioGroupControllerProps,
} from './components/radio-group-controller';
import { SwitchController, SwitchControllerProps } from './components/switch-controller';
import { useListItemGroup } from './context';

// Define the ControlVariant type and map to specific option types
export type ControlVariantOptions =
  | { variant: 'switch'; options: SwitchControllerProps }
  | { variant: 'checkbox'; options: CheckboxControllerProps }
  | { variant: 'quantityStepper'; options: QuantityStepperControllerProps }
  | { variant: 'quantityCounter'; options: QuantityCounterControllerProps }
  | { variant: 'radioGroup'; options: RadioGroupControllerProps }
  | { variant: 'picker'; options: PickerControllerProps };

type ListItemControlProp = ViewProps & {
  item: ControlVariantOptions;
};

/**
 * Any new GLOBAL state like a generic error state propagation
 * should be determined also inside the component or define the expected
 * behavior on each scenario.
 * */
export const ListItemControl = ({ item, ...viewProps }: ListItemControlProp) => {
  const { variant, options } = item;
  const {
    disabled: listGroupDisabled,
    header: { enable: enableListFromHeader },
  } = useListItemGroup();
  const isListDisabledFromHeader = !enableListFromHeader;
  const disabled: boolean = options.disabled || listGroupDisabled || isListDisabledFromHeader;

  switch (variant) {
    case 'switch':
      const switchProps = { ...options, disabled };
      return <SwitchController {...switchProps} {...viewProps} />;
    case 'checkbox':
      const checkboxProps = { ...options, disabled };
      return <CheckboxController {...checkboxProps} {...viewProps} />;
    case 'quantityStepper':
      const quantityStepperProps = { ...options, disabled };
      return <QuantityStepperController {...quantityStepperProps} {...viewProps} />;
    case 'quantityCounter':
      const QuantityCounterProps = { ...options, disabled };
      return <QuantityCounterController {...QuantityCounterProps} {...viewProps} />;
    case 'radioGroup':
      const RadioGroupProps = { ...options, disabled };
      return <RadioGroupController {...RadioGroupProps} {...viewProps} />;
    case 'picker':
      const pickerControllerProps = { ...options, disabled };
      return <PickerController {...pickerControllerProps} {...viewProps} />;
    default:
      return null;
  }
};
