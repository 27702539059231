import { PropsWithChildren } from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';

import { LogoSvg, tokens } from '@fhs/ui';

import { AuthHeader } from '../auth-header';

export function AuthContainerMobile({ children }: PropsWithChildren) {
  return (
    <SafeAreaView>
      <AuthHeader height={125} repeat={7} />
      <View style={styles.container}>
        <View>
          <LogoSvg style={styles.logo} />
        </View>
        <View style={styles.content}>{children}</View>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    backgroundColor: tokens.colors.$white,
    alignItems: 'center',
    marginVertical: 157,
    width: '100%',
    height: 1000,
    padding: 32,
    position: 'absolute',
  },
  content: {
    marginVertical: 40,
    width: '85%',
  },
  logo: { alignSelf: 'center', marginVertical: -60 },
});
