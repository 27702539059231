import { StyleSheet } from 'react-native';

import { RadioGroup, RadioGroupProps } from '../../radio-group';
import { XStack } from '../../stack';

export type RadioGroupControllerProps = RadioGroupProps & {
  disabled: boolean; // RadioGroup does not have a global disabled.
  radioList: Array<{ label: string; value: string; disabled?: boolean }>;
};

export const RadioGroupController = ({
  onChange,
  radioList,
  value,
  disabled,
  ...viewProps
}: RadioGroupControllerProps) => {
  return (
    <RadioGroup style={styles.radioGroup} value={value} onChange={onChange}>
      {radioList.map(radioItem => (
        <RadioGroup.Item
          label={radioItem.label}
          value={radioItem.value}
          disabled={disabled || radioItem.disabled}
        >
          <XStack style={{ gap: 6 }} {...viewProps}>
            <RadioGroup.Label />
            <RadioGroup.Control />
          </XStack>
        </RadioGroup.Item>
      ))}
    </RadioGroup>
  );
};

const styles = StyleSheet.create({
  radioGroup: {},
});
