import { ViewProps } from 'react-native';
import { ImageSourcePropType } from 'react-native/Libraries/Image/Image';

import { PickerNotSelected } from './picker-not-selected';
import { PickerSelected } from './picker-selected';

export type PickerProps = ViewProps & {
  disabled?: boolean;
  label: string;
  handlePress: () => void; // open modal with options?
  selected: PickerSelected;
  imageNotSelected: ImageSourcePropType;
};

export const Picker = ({
  disabled,
  handlePress,
  imageNotSelected,
  label,
  selected,
}: PickerProps) => {
  if (!selected) {
    return (
      <PickerNotSelected
        role="button"
        disabled={disabled}
        label={label}
        onPress={handlePress}
        imageNotSelected={imageNotSelected}
      />
    );
  }

  return (
    <PickerSelected role="button" disabled={disabled} onPress={handlePress} selected={selected} />
  );
};
