import { StyleSheet } from 'react-native';

import { tokens } from '../../tokens';
import { Text, TextProps } from '../text/';

export type PickerLabelProps = TextProps & {
  label: string;
};

export function PickerLabel({ disabled, label, ...textProps }: PickerLabelProps) {
  return (
    <Text.Ui
      size="md"
      {...textProps}
      style={[styles.touchableText, textProps?.style, disabled && styles.touchableTextDisabled]}
    >
      {label}
    </Text.Ui>
  );
}

const styles = StyleSheet.create({
  touchableText: {
    alignSelf: 'center',
    flex: 1,
  },
  touchableTextDisabled: {
    color: tokens.colors.$disabledText,
  },
});
