import { withStaticProperties } from '@fhs/utils';

import { Switch as SwitchItem } from './switch';
import { SwitchControl as Control } from './switch-control';
import { SwitchLabel as Label } from './switch-label';

export const Switch = withStaticProperties(SwitchItem, {
  Label,
  Control,
});

export { SwitchContext, useSwitch } from './context';
export type { SwitchControlProps } from './switch-control';
export type { SwitchLabelProps } from './switch-label';
export type { SwitchProps } from './switch';
