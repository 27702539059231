import { QuantityCounter } from '../../quantity';
import { QuantityCounterProps } from '../../quantity';

export type QuantityCounterControllerProps = QuantityCounterProps;

export const QuantityCounterController = (props: QuantityCounterControllerProps) => {
  return (
    <QuantityCounter {...props}>
      <QuantityCounter.Label />
      <QuantityCounter.Stepper />
    </QuantityCounter>
  );
};
