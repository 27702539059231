import { Stack } from 'expo-router';

import { useMqSelect } from '@fhs/ui';

import { getScreenTitleHeader } from '../../../components/get-screen-title-header';

export default function OffersStackLayout() {
  const isDesktop = useMqSelect({ $gteDesktop: true }, false);

  return (
    <Stack
      screenOptions={{
        header: getScreenTitleHeader({ cannotGoBackHref: '/v2/menu' }),
        headerShown: !isDesktop,
        title: 'Offers & Rewards',
      }}
    >
      <Stack.Screen
        name="[slug]"
        options={{
          presentation: isDesktop ? 'transparentModal' : 'modal',
          contentStyle: {
            backgroundColor: 'transparent',
          },
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="all"
        options={{
          title: 'See All Offers',
        }}
      />
    </Stack>
  );
}

// Disabled because this is causing module import errors on production build
// Presumably because it duplicate `fresh/routing/src/routes/menu/_layout.tsx`
// export const unstable_settings = {
// };
