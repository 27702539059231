import { Link, Redirect } from 'expo-router';
import Head from 'expo-router/head';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { GetCart, useQuery } from '@fhs/cart';
import { ScreenMenuListWithQuery } from '@fhs/menu/src/screens/screen-menu-list';
import { Button, MobileBottomBarServiceModeLocation, MqSwitch, YStack, tokens } from '@fhs/ui';
import { useLocale } from '@fhs-legacy/frontend/src/state/intl';
import { REGIONS } from '@fhs-legacy/frontend/src/state/intl/types';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { useServiceModeContext } from '@fhs-legacy/frontend/src/state/service-mode';
import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';
import { isSSG } from '@fhs-legacy/frontend/src/utils/environment';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

import { useServiceModeLocation } from '../../../../utils/use-get-service-mode-location-type';
export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function MenuIndexPage() {
  const { data: cart } = useQuery(GetCart);

  const { store } = useStoreContext();
  const { region } = useLocale();
  const regionSimple = region === REGIONS.US ? 'us' : 'ca';
  const { isDelivery } = useServiceModeContext();

  const serviceModeLocationProps = useServiceModeLocation();

  // TODO(simplified-menu): Remove when simplified menu is out of beta
  const enableSimplifiedMenuBeta = useFlag<boolean>(LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA);
  const enableSimplifiedMenuBetaDetailView = useFlag<boolean>(
    LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA_DETAIL_VIEW
  );

  // The Flat will be undefined initially, so we need to return null to avoid rendering the `menu` page,
  // because that will cause a redirect to the store locator if the store is not set.
  if (enableSimplifiedMenuBeta === undefined && !isSSG) {
    return <ActivityIndicator />;
  }
  if (!enableSimplifiedMenuBeta && !isSSG) {
    return <Redirect href="/menu" />;
  }
  // END of TODO

  // XXX This needs to be removed once we have a
  const selectedRestaurantId = store.number || '0';
  // TODO, maybe entries shouldn't be `null`?
  const cartEntriesLength = cart?.entries?.length || 0;
  const cartHasEntries = cartEntriesLength > 0;
  const buttonText = cartHasEntries ? `Checkout (${cartEntriesLength})` : 'Your Cart is Empty';
  const buttonDisabled = !cartHasEntries;

  return (
    <>
      <Head>
        <title>Menu - Firehouse Subs</title>
      </Head>
      <SafeAreaView style={styles.safeArea}>
        <View style={styles.flex1}>
          <ScreenMenuListWithQuery
            selectedRestaurantId={selectedRestaurantId}
            region={regionSimple}
            // TODO(simplified-menu): remove this after 100% rollout
            // Temporary prop to link to the legacy menu detail view
            linkToLegacyMenuDetailView={!enableSimplifiedMenuBetaDetailView}
          />
        </View>
        <MqSwitch
          $ltDesktop={() => (
            <YStack style={styles.footer}>
              <Link href={isDelivery ? routes.address : routes.storeLocator} asChild>
                <MobileBottomBarServiceModeLocation {...serviceModeLocationProps} />
              </Link>
              <View style={styles.footerButtonContainer}>
                <Link href="/v2/cart" asChild>
                  <Button style={styles.button} disabled={buttonDisabled}>
                    <Button.Text>{buttonText}</Button.Text>
                  </Button>
                </Link>
              </View>
            </YStack>
          )}
        />
      </SafeAreaView>
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    flex: 1,
    padding: 16,
  },
  flex1: {
    flex: 1,
  },
  safeArea: {
    backgroundColor: tokens.colors.$white,
    flex: 1,
  },
  footer: {
    maxWidth: '100%',
    overflow: 'hidden',
  },
  footerButtonContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 12,
  },
});
