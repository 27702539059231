import React from 'react';

import { Checkbox, CheckboxProps } from '../../checkbox';
import { XStack } from '../../stack';

export type CheckboxControllerProps = Omit<CheckboxProps, 'children'>;

export const CheckboxController = ({
  onChange,
  checked,
  disabled,
  label,
  ...viewProps
}: CheckboxControllerProps) => {
  return (
    <Checkbox disabled={disabled} label={label} onChange={onChange} checked={checked}>
      <XStack style={{ justifyContent: 'space-between' }} {...viewProps}>
        <Checkbox.Label />
        <Checkbox.Control />
      </XStack>
    </Checkbox>
  );
};
